import React, { useRef, useEffect, useState } from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Sparkle from "react-sparkle";

const Offers = ({ offerTitle, offersList }) => {
  const offerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = offerRef.current;
      const top = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      let haveSeen = false;

      if (!haveSeen) {
        if (top < windowHeight) {
          setIsVisible(true);
          haveSeen = true;
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`offers__container ${isVisible ? "animated" : ""}`}
      ref={offerRef}
    >
      {/* Visa titeln på både svenska och engelska */}
      <h1 className="offer__title glitter">
        {offerTitle.swedish.toUpperCase()}
      </h1>

      <div style={{ position: "relative" }}>
        {/*
          <Sparkle
          count={25}
          fadeOutSpeed={5}
          flicker={true}
          flickerSpeed={"slowest"}
        />

          */}

        {/* Render the list of offers */}
        <ul className="offer__content">
          {offersList.map((offer, index) => (
            <li key={index} className="offer__item">
              {/* Visa varje erbjudande på svenska och engelska */}
              <div>
                <span>{offer.swedish}</span>
              </div>
            </li>
          ))}
          <DirectionsCarIcon />
        </ul>
      </div>
      <h1 className="offer__title glitter">
        {offerTitle.english.toUpperCase()}
      </h1>

      <div style={{ position: "relative" }}>
        {/*
          <Sparkle
          count={25}
          fadeOutSpeed={5}
          flicker={true}
          flickerSpeed={"slowest"}
        />

          */}

        {/* Render the list of offers */}
        <ul className="offer__content">
          {offersList.map((offer, index) => (
            <li key={index} className="offer__item">
              {/* Visa varje erbjudande på svenska och engelska */}
              <div>
                <span>{offer.english}</span>
              </div>
            </li>
          ))}
          <DirectionsCarIcon />
        </ul>
      </div>
    </div>
  );
};

export default Offers;
